$(function(){
	var SITE = (function(){
		var $cards = $('.quote-slide');
		var cardsLength = $('.quote-slide').length;
		var totalHeight;
		var pageHeight;
		
		function waitForWebfonts(fonts, callback) {
			    var loadedFonts = 0;
			    for(var i = 0, l = fonts.length; i < l; ++i) {
			        (function(font) {
			            var node = document.createElement('span');
			            // Characters that vary significantly among different fonts
			            node.innerHTML = 'giItT1WQy@!-/#';
			            // Visible - so we can measure it - but not on the screen
			            node.style.position      = 'absolute';
			            node.style.left          = '-10000px';
			            node.style.top           = '-10000px';
			            // Large font size makes even subtle changes obvious
			            node.style.fontSize      = '300px';
			            // Reset any font properties
			            node.style.fontFamily    = 'sans-serif';
			            node.style.fontVariant   = 'normal';
			            node.style.fontStyle     = 'normal';
			            node.style.fontWeight    = 'normal';
			            node.style.letterSpacing = '0';
			            document.body.appendChild(node);

			            // Remember width with no applied web font
			            var width = node.offsetWidth;

			            node.style.fontFamily = font + ', sans-serif';

			            var interval;
			            function checkFont() {
			                // Compare current width with original width
			                if(node && node.offsetWidth != width) {
			                    ++loadedFonts;
			                    node.parentNode.removeChild(node);
			                    node = null;
			                }

			                // If all fonts have been loaded
			                if(loadedFonts >= fonts.length) {
			                    if(interval) {
			                        clearInterval(interval);
			                    }
			                    if(loadedFonts == fonts.length) {
			                        callback();
			                        return true;
			                    }
			                }
			            };

			            if(!checkFont()) {
			                interval = setInterval(checkFont, 50);
			            }
			        })(fonts[i]);
			    }
			};
		
		function calcHeightAbove(nthBlock){
			var heightAbove = 0;
			
			$cards.each(function(index, element) {
				if (index < nthBlock) {
					heightAbove = heightAbove + $( element ).outerHeight();
				}
			});
			
			return heightAbove;
		}
		
		function initQuotesParams(){
			totalHeight = 0;
			pageHeight = window.innerHeight;
			
			$cards.each(function(index, element) {
				// console.log(index, element);
				$( element ).removeClass('-natural -stuck -fading-in-still'); // Reset positioning for when resizing
				
				totalHeight = totalHeight + $( element ).outerHeight();
				$( element ).css('position', 'absolute');
				$( element ).css('z-index', index);
				
				topVal = calcHeightAbove(index)
				
				$( element ).css('top', topVal + 'px');
				$( element ).attr('data-top', ($('.quote-slide')[index].offsetTop) );
				$( element ).attr('data-calc-height', $( element ).outerHeight() );
			});
			
			$('.scroller').css('height', totalHeight);
			
		};
		
		waitForWebfonts(['Quarto A', 'Quarto B'], function() {
			initQuotesParams();			
		});
		
		function opacityNormalise(value) {
			if (value < 0) {
				var newValue = 0;
			}
			else if (value > 1) {
				var newValue = 1;
			}
			else {
				var newValue = value;
			}
			
			return newValue;
		}
		
		function checkPositions(){
			$cards.each(function(index, element) {
				console.log( 
					index, 
					$cards.eq( (index - 1) ).hasClass('text') );
					
				if ($cards.eq( (index - 1) ).hasClass('text') ) {
					$(this).addClass('-follows-text');
				}
				// 
				if ( $cards.eq( (cardsLength - 1) ) ) {
					$(this).addClass('-last-child');
				}
			});
			

		}
		
		function updateCards() {
			$cards.each(function(index, element) {
				
				if(window.pageYOffset > $(this).attr('data-top') ) {
					$(this).addClass('-stuck').removeClass('-natural');
				} else {
					$(this).addClass('-natural').removeClass('-stuck');
				}
				
				if( 
					window.pageYOffset < ($(this).attr('data-top') + (pageHeight) )
				) {
					var difference = ( parseInt( $(this).attr('data-top'), 10 ) + (pageHeight)) - window.pageYOffset;
					var fade = difference / pageHeight;
					var opacityCorrected = (2 - fade);
					var opacityNormalised = opacityNormalise(opacityCorrected);
					
					if (0 < opacityNormalised 
						&& opacityNormalised < 1 
						&& $(this).hasClass('image') 
					) {
						if ( $cards.eq( (index - 1) ).hasClass('image') ) {
							$(this).addClass('-fading-in-still');
						}
					} else {
						$(this).removeClass('-fading-in-still');
					}
					
					if( $(this).hasClass('image') ){
						$(this).css('opacity', opacityNormalised );
					}
				}
			});
		}
				
		function checkOffsetQuotesPanel() {
			var scrollZoneHeight = 250;
			var scrollOffset = window.pageYOffset;
			
			if (scrollOffset < scrollZoneHeight) {
				showQuotesPanel();
			} else if( scrollOffset > (totalHeight - pageHeight - scrollZoneHeight) ){
				showQuotesPanel();
			} else {
				hideQuotesPanel();
			};
		}
		
		function showQuotesPanel() {
			$('.quotes-panel').removeClass('-hidden');
		}
		
		
		function hideQuotesPanel() {
			$('.quotes-panel').addClass('-hidden');
		}
		
		var resizeRAF = null;
		function doResize() {
			initQuotesParams();
			updateCards();
			resizeRAF = null;
		}
		function onResize() {
			if (resizeRAF === null) {
				resizeRAF = window.requestAnimationFrame(doResize);
			}
			// clearTimeout(resizePostponeTimer);
			// resizePostponeTimer = setTimeout(doResize, 0);
		}
		
		var scrollRAF = null;
		function doScroll() {
			updateCards();
			checkOffsetQuotesPanel();
			scrollRAF = null;
		}	
		function onScroll() {
			if (scrollRAF === null) {
				scrollRAF = window.requestAnimationFrame(doScroll);
			}
			// clearTimeout(scrollPostponeTimer);
			// scrollPostponeTimer = setTimeout(doScroll, 0);
		}	
		
		$(window).on('scroll', onScroll);
		$(window).on('resize', onResize);

		
		function kickIt() {
			checkPositions();
			onResize(); // For IE
		}
		
		// window.addEventListener('resize', onResize, false);
		
		// return an object that exposes our greeting function publicly
		return {
			kickIt: kickIt
		};

	})();
	SITE.kickIt();
  
});