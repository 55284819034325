$(function(){
	var SITE = (function(){
		
		var pageSize = {
			lg: 1200,
			md: 800,
			sm: 560,
			xs: 360
		};
		
		// Tool – Update Query String Parameter
		function updateQueryStringParameter(uri, key, value) {
			var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
			var separator = uri.indexOf('?') !== -1 ? "&" : "?";
			if (uri.match(re)) {
				var newurl = uri.replace(re, '$1' + key + "=" + value + '$2');
			}
			else {
				var newurl =  uri + separator + key + "=" + value;
			}
			
			if (history.pushState) {
					window.history.pushState({path:newurl},'',newurl);
			}
		}
		
		function getJsonFromUrl() {
		  var query = location.search.substr(1);
		  var result = {};
		  query.split("&").forEach(function(part) {
		    var item = part.split("=");
		    result[item[0]] = decodeURIComponent(item[1]);
		  });
		  return result;
		}
		
		// Tool – Apply Query Variable
		function applyQueryVariable(variable) {
			var query = window.location.search.substring(1);
			var vars = query.split('&');
			for (var i = 0; i < vars.length; i++) {
					var pair = vars[i].split('=');
					if (decodeURIComponent(pair[0]) == variable) {
							return decodeURIComponent(pair[1]);
					}
			}
			console.log('Query variable %s not found', variable);
		}

		function isEmptyText( el ){
		   return !$.trim(el.html())
		}
			
		// Wrap first four letters on each section in a span to apply small caps
		function applyStoryTextLead(){
			
			$('div.story-text section').each(function(index, element){
					
				var $paragraphs = $(element).find('> p');
				var found = false;
				$paragraphs.each(function(index, element){
					if (!found) {
						var $p = $(this);
						
						if (isEmptyText($p)) {
							// removes any leading empty p's that may be present
							$p.remove();
						} else {
							found = true;
							
							// wraps first 4 words in a span to UPPERCASE them
							var originalHTML = $p.html();
							var replacedHTML = originalHTML.replace(/(([^\s]+\s\s*){4})(.*)/,"<span class=\"lead\">$1</span>$3");
							$p.html(replacedHTML);
						}
					}
				});
			});
		}

		var nav = {
			el: $('.horizontal-nav'),
			selected: $('.selected'),
			filter: 'all',
			filterTrigger: $('a[data-filter-value]'),
			x: $('.horizontal-nav x-icon'),
			mobileNavTrigger: $('.mobile-nav-trigger'),
			removeFilter: $('.js-remove-filter'),
			headerHeight: $('.primary-header').height(),
			
			positionMobileNav: function() {
				nav.headerHeight = $('.page-title').outerHeight();
				
				if ($(window).width() < pageSize.md) {
					// $('.mobile-nav').css('top', nav.headerHeight + 'px');
					$('.mobile-nav').css('top', '0px');
				}
				else {
					$('.mobile-nav').css('top', '');
				}
			},
			
			toggleMobileNav: function() {
				nav.positionMobileNav();
				$('.primary-header').toggleClass('-mobile-nav-open');
				if ($('.mobile-nav-trigger').attr('data-state') == 'close') {
						$('.mobile-nav-trigger').attr('data-state', 'open');
				} else {
					$('.mobile-nav-trigger').attr('data-state', 'close');
				}
			}
		}
	
		var filmViewer = {
			el: {
				filmScreen: $('.film-viewer'),
				iframe: $('.film-viewer iframe'),
				closeButton: $('.film-viewer .close'),
				overlay: $('.overlay'),
				trigger: $('.js-film-viewer-trigger')
			},
			
			settings: {
				name: $('.js-film-name'),
				visible: false,
			},
			
			showFilmViewer: function(e){
				var isVideoReference = $(this).is('[data-vimeo-id]');
				var isQuickPlay = $(this).hasClass('quick-play-prompt');
				var filmMode = (homepage.currentFilter === 'film');
				var vimeoId = $(this).attr('data-vimeo-id');
				var name = $(this).attr('data-name');
				
				if ( $(this).parents('.story-block').length ) { 
					var homeQuickPlay = true;
				} else {
					var homeQuickPlay = false;
				}
				
				if(!isVideoReference) {
					return;
				}
				
				if(homepage.previewContainer.length && !filmMode && !homeQuickPlay) {
					return;
				}
	
				e.preventDefault();
				var videoSrc = "//player.vimeo.com/video/" + vimeoId + '?autoplay=1';
				filmViewer.el.iframe.attr('src', videoSrc);
				filmViewer.settings.name.html(name);
				filmViewer.el.overlay.addClass('-visible');
				filmViewer.el.filmScreen.addClass('-visible');
				filmViewer.settings.visible = true;
				
			},
			
			hideFilmViewer: function(e){
				filmViewer.el.overlay.removeClass('-visible');
				filmViewer.el.filmScreen.removeClass('-visible');
				filmViewer.el.iframe.attr('src', '');
				filmViewer.settings.visible = false;
				e.preventDefault();
			}
		}
	
		var quotesPage = {
			el: {
				pages: $('.image-slide')
			},
			
			settings: {
				totalPages: $('.image-slide').length,
				pageHeight: 480,
				offset: 64 // Height of the header
			},
			
			enterFirstPanel: function(){
				$('.quotes-page-action').addClass('-hide');
				$('.next-button').addClass('-clear');
			},
			
			leaveFirstPanel: function(){
				$('.quotes-page-action').removeClass('-hide');
				$('.next-button').removeClass('-clear');
			},
			
			enterLastPanel: function(){
				$('.next-button').addClass('-hide');
			},
			
			leaveLastPanel: function(){
				$('.next-button').removeClass('-hide');
			},
		
 			// Transition between slides
			slideTransition: function(trigger, e) {
				if ($(window).width() < pageSize.md) {
					return;
				}
				
				var nthPanel = parseInt( $(trigger).attr('data-id') );
				
				if(e.type == 'enter') {
					quotesPage.el.pages.eq(nthPanel).addClass('-current');

					if(nthPanel == 0) {
						quotesPage.enterFirstPanel();
					}
					if(nthPanel == 1) {
						quotesPage.leaveFirstPanel();
					}
					if(nthPanel == (quotesPage.settings.totalPages - 1)) {
						quotesPage.enterLastPanel();
					}
				} else if( e.type == 'leave' ) {
					quotesPage.el.pages.eq(nthPanel).removeClass('-current');
					// console.log('leave: '+ nthPanel);
					if(nthPanel == 1) {
						quotesPage.enterFirstPanel();
					}
					if(nthPanel == (quotesPage.settings.totalPages - 1)) {
						quotesPage.leaveLastPanel();
					}
				}
				
				// Update URL
				$('.next-button').attr('href', '#panel-' + (nthPanel + 1) )

			},
			
			// Set up slides
			setupSlides: function(){
				var $dummyScroll = $('.dummy-scroll');
				
				if ( $dummyScroll.length ) {
					$dummyScroll.height( quotesPage.settings.totalPages * quotesPage.settings.pageHeight );
					quotesPage.el.pages.each(function(index) {
						$dummyScroll.append(
							'<div class="trigger" id="panel-' 
							+ $(this).attr('data-id') 
							+ '" data-id="' 
							+ $(this).attr('data-id') 
							+ '" style="height: ' 
							+ quotesPage.settings.pageHeight +'px; top: ' + (quotesPage.settings.pageHeight * (index - 1)) + 'px"></div>'
						);
					});
					
					var controller = new ScrollMagic.Controller({
						globalSceneOptions: {
							triggerHook: 'onLeave'
						}
					});
					
					// get all slides
					var slides = document.querySelectorAll(".trigger");

					// create scene for every slide
					for (var i=0; i<slides.length; i++) {
						new ScrollMagic.Scene({
							triggerElement: slides[i]
						})
						.on("enter leave", function (e) {
							quotesPage.slideTransition(this.triggerElement(), e);
						})
						// .on("start end", function (e) {
						// 	slideTransition(this.triggerElement(), e);
						// })
						// .addIndicators() //  (requires plugin)
						.addTo(controller);
					}
				}
			}
		}
	
		var homepage = {
			currentFilter: null,
			previewContainer: $('.all-stories'),
			previewBlock: $('.story-block'),
			
			// Parse filter string and from dom and set up for application
			parseFilter: function(e){
				// Set values
				var parsedFilter = $(this).data('filter-value');
				var filterClass = ".js-has-" + parsedFilter ;

				// Ignore if filter is already set as is
				if ( parsedFilter == homepage.currentFilter ) { 
					homepage.currentFilter = parsedFilter;
				} else {
						homepage.currentFilter = parsedFilter;
				}
				
				// Change classes
				nav.filterTrigger.removeClass('selected');
				$(this).addClass('selected');	
				
				// Change URL
				// updateQueryStringParameter(window.location.href, 'v', parsedFilter);
				
				if (parsedFilter == 'film') {
					var newSlug = siteRoot + 'films/';
				} else if (parsedFilter == 'story') {
					var newSlug = siteRoot + 'stories/';
				} else {
					var newSlug = siteRoot;
				}
				window.history.pushState("", "Conversations with Teen Mums", newSlug);	
			
				// Change classes
				$('.all-stories').attr('data-filter', parsedFilter);
				
				// Trigger filter change
				homepage.applyFilter(parsedFilter);
				homepage.filterUrlChange(parsedFilter);
			},
			
			// Remove filter, used when clicking x buttons
			removeFilter: function(e){
				e.stopPropagation();
				
				var newSlug = siteRoot;
				window.history.pushState("", "Conversations with Teen Mums", newSlug);	
			
				// Change classes
				homepage.currentFilter = 'all';
				$('.all-stories').attr('data-filter', 'all');
			
				nav.filterTrigger.removeClass('selected');
				homepage.applyFilter('all');
				homepage.filterUrlChange('all');
			},

			// Change URLs when a section is selected to link to relevant aspects of the block
			filterUrlChange: function(filter){
				console.log('** filterUrlChange ' + filter);
				if (filter === 'story') {
					$('.js-has-story').each(function(index, element) {
						var storyLink = $(this).attr('data-story-href');
						$(this).attr('href', '');
						$(this).attr('href', storyLink);
						// console.log('Filter story url', index, $(this));
					})
				}
				else {
					homepage.previewBlock.each(function(index, element) {
						var quotesLink = $(this).attr('data-quotes-href');
						$(this).attr('href', quotesLink);
						// console.log('Filter story url', index, $(this));
					});
				}
			},
			
			// Apply filter to homepage block
			applyFilter: function(filter){
				var filterClass = ".js-has-" + filter ;
				homepage.previewBlock.addClass('-hide');
				
				if (filter === 'all') {
					$('.nav-all-link').addClass('-invisible');
				}
				
				else if (filter === undefined) {
					return;
				} 
				
				else {
					$('.nav-all-link').removeClass('-invisible');
				}
				
				setTimeout(function(){
					homepage.previewBlock.addClass('-remove');
					$(filterClass).removeClass('-remove');
					
					if (filter == 'all') {
						homepage.previewBlock.removeClass(' -remove');
					}
				}, 250);
				
				setTimeout(function(){
					if (filter == 'all') {
						homepage.previewBlock.removeClass('-hide -remove');
					}
					else {
						// console.log( $(filterClass) );
						$(filterClass).removeClass('-hide');					
					}
				}, 300);
			},
			
			applyFilterImmediate: function(filter){
				if (filter === 'all') {
					return;
				}
				else if (filter === undefined) {
					return;
				}
				else {
					$('.nav-all-link').removeClass('-invisible');
					homepage.previewContainer.attr('data-filter', filter);
					homepage.currentFilter = filter;
				}
				var filterClass = ".js-has-" + filter ;
				homepage.previewBlock.addClass('-remove');
				$(filterClass).removeClass('-remove');
				homepage.filterUrlChange(filter);
			}
		};
		
		var stickyHeader = {
			el: {
				header: $('.primary-header.js-sticky'),
				lockTrigger: $('.primary-header.js-sticky .horizontal-nav')
			},
			
			settings: {
				lockTriggerHeight: 28,
				lockClass: '-pinned-top',
				isSpacedLayout: $('.primary-header.-spaced.js-sticky').length > 0,
				isCondensedLayout: $('.primary-header.-hanging-logo').length > 0 
			},
			
			lock: function(){
				// stickyHeader.el.header.removeClass('-spaced')
				stickyHeader.el.header.addClass(stickyHeader.settings.lockClass)
			},
			
			unlock: function(){
				// stickyHeader.el.header.addClass('-spaced')
				stickyHeader.el.header.removeClass(stickyHeader.settings.lockClass)
			},
			
			scrollWatch: function(){
				if ($(window).width() < pageSize.md) {
					return;
				}
				var scrollTop = $(window).scrollTop();
				if (stickyHeader.settings.isSpacedLayout) {
					var navOffset = stickyHeader.el.lockTrigger[1].getBoundingClientRect().top;
					if (navOffset < stickyHeader.settings.lockTriggerHeight) {
						stickyHeader.lock();
					} 
					if (scrollTop < 80) {
						stickyHeader.unlock();
					}
				}
				if (stickyHeader.settings.isCondensedLayout){
					if (scrollTop > 30) {
						stickyHeader.lock();
					}
					if (scrollTop < 29) {
						stickyHeader.unlock();
					}
				}
			}
		}
		
		function checkURL() {
			// var urlQuery = getJsonFromUrl(window.location);
			// console.log(urlQuery.v);
			
			if(window.location.href.indexOf("films") > -1) {
				homepage.applyFilterImmediate('film');			 
			}
			
			
			if(window.location.href.indexOf("stories") > -1) {
				homepage.applyFilterImmediate('story');			 
			}

		}
		
		function resizeEvents(){
			nav.positionMobileNav();
		};
		
		function layout(){
			quotesPage.setupSlides();
			applyStoryTextLead();
		};
    
		function init() {
			checkURL();
			layout();
		};
		
		filmViewer.el.trigger.on( 'click', filmViewer.showFilmViewer );
		filmViewer.el.closeButton.on('click', filmViewer.hideFilmViewer );
		homepage.previewBlock.on('click', filmViewer.showFilmViewer );
		// $('[data-filter="film"] .story-block').on('click', filmViewer.showFilmViewer );
		$('.quick-play-prompt').on('click', filmViewer.showFilmViewer );
		nav.filterTrigger.on('click', homepage.parseFilter );
		nav.removeFilter.on('click', homepage.removeFilter );
		nav.mobileNavTrigger.on('click', nav.toggleMobileNav );
		$(document).on('scroll', stickyHeader.scrollWatch);
		$(window).on('resize', resizeEvents);

		
		return {
			init: init
		};

	})();
	SITE.init();
  
});


